import { useState, useEffect, useRef } from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Logo from "./Logo";
import Navbar from "./Navbar";

const Header = () => {
  const [navOpen, setNavOpen] = useState(false);
  const targetRef = useRef(null); // Ref for the element to keep scrollable if needed

  const showHideNav = () => {
    setNavOpen(!navOpen);
    if (!navOpen) {
      disableBodyScroll(targetRef.current);
    } else {
      enableBodyScroll(targetRef.current);
    }
  };

  const isDesktop = (e) => {
    if (e.matches) {
      setNavOpen(false);
      enableBodyScroll(targetRef.current);
    }
  };

  useEffect(() => {
    let mediaQuery = window.matchMedia("(min-width: 768px)");
    mediaQuery.addEventListener("change", isDesktop);
    return () => {
      mediaQuery.removeEventListener("change", isDesktop);
      enableBodyScroll(targetRef.current); // Ensure body scroll is enabled on cleanup
    };
  }, []);

  return (
    <header className={navOpen ? "show" : undefined} ref={targetRef}>
      <a href="/" aria-label="Home">
        <Logo />
      </a>
      <button
        className="btn-main-nav"
        onMouseDown={(e) => e.preventDefault()}
        onClick={showHideNav}
      >
        <span className="hamburger-icon btn-main-nav">
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </span>
        <span className="sr-only">Menu</span>
      </button>

      <Navbar handleShowHideNav={showHideNav} />
    </header>
  );
};

export default Header;
